import * as React from "react";
import { Button, Layout, Title } from "../components";

const IndexPage = () => {
  return (
    <>
      <title>
        Cémpes Vastgoed - Dé alleskunner voor uw vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="Dé alleskunner voor uw vastgoedprojecten" />

        <div className="grid sm:grid-cols-2 gap-6 mt-2">
          <div>
            <p>
              U staat voor de uitdaging uw vastgoed te ontwikkelen en
              realiseren, renoveren of onderhouden. Stuk voor stuk trajecten
              waar veel bij komt kijken om, binnen de gestelde kaders en
              randvoorwaarden, spoedig het gewenste resultaat te behalen. Kennis
              van regelgeving, van de markt en de juiste ervaring voor de
              begeleiding van de ontwikkelings- en bouwprocessen zorgen voor een
              succesvol verloop van uw vastgoedproject. Cémpes Vastgoed heeft
              deze kennis en is dé partner die u in alle stappen van het project
              volledig ontzorgt.
            </p>
            <br />
            <p>
              Ook heeft Cémpes Vastgoed de nodige ervaring om moeizaam lopende
              of vastgelopen projecten vlot te trekken en tot een goed einde te
              brengen.
            </p>
          </div>
          <div>
            <p>
              Cémpes Vastgoed is onder andere gespecialiseerd op het gebied van
              vastgoedontwikkeling, -beheer en –management. Wij gaan door waar
              anderen stoppen. Cémpes Vastgoed verzorgt de coördinatie van het
              gehele ontwikkelings- en bouwproces, voor elk project. Van de
              eerste ideeën en schetsen tot en met de oplevering en nazorg van
              het nieuwbouw, beheer- en renovatieprojecten. Wij zijn altijd nauw
              betrokken en gaan voor het maximale resultaat.
            </p>
            <br />
            <p>
              Jan Willem Snelder heeft na een jarenlang partnerschap Cémpes
              Vastgoed opgericht. In de portofolio vindt u een kleine selectie
              referentieprojecten.
            </p>
          </div>
        </div>

        <div className="grid sm:grid-cols-3 gap-5 mt-7">
          <Button text="Nieuws" link="/nieuws" />
          <Button text="Portofolio" link="/portofolio" />
          <Button text="Contact" link="/contact" />
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
